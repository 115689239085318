import {
  GET_DTV_TWITCH_CHANNEL_STATUS_SUCCESS,
  GET_DTV_TWITCH_CHANNEL_STATUS_FAILED,
} from "../constants/actionTypes"

const initialState = {
  twitch: {
    stream: null,
  },
}

export default function twitchReducer(state = initialState.twitch, action) {
  switch (action.type) {
    case GET_DTV_TWITCH_CHANNEL_STATUS_SUCCESS:
      return {
        ...state,
        stream: action.payload.stream,
      }
    case GET_DTV_TWITCH_CHANNEL_STATUS_FAILED:
      return {
        ...state,
        stream: null,
      }
    default:
      return state
  }
}
