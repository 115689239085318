import {
  GET_UPLOAD_PROMPT,
  GET_UPLOAD_PROMPT_SUCCESS,
  HASH_DEMO_FILE_STARTED,
  HASH_DEMO_FILE_PROGRESS,
  HASH_DEMO_FILE_SUCCESS,
  DEMO_FILE_PARSE_STARTED,
  DEMO_FILE_PARSE_SUCCESS,
  DEMO_FILE_PARSE_FAILED,
  UPLOAD_DEMO_FILE_STARTED,
  UPLOAD_DEMO_FILE_SUCCESS,
} from "../constants/actionTypes"

const initialState = {
  uploads: {
    parsingDemo: false,
    hashingDemo: false,
    hashingProgress: 0,
    promptingUpload: false,
    demoHash: null,
    uploadToken: null,
    demoFile: null,
    playerTeamA: null,
    playerTeamB: null,
    droppedFiles: null,
    uploading: false,
    readyToSaveReplay: false,
  },
}

export default function uploadsReducer(state = initialState.uploads, action) {

  switch (action.type) {
    case HASH_DEMO_FILE_STARTED:
      return {
        ...state,
        hashingDemo: true,
        hashingProgress: 0,
        demoHash: null,
      }

    case HASH_DEMO_FILE_PROGRESS:
      return {
        ...state,
        hashingProgress: action.payload.hashProgress,
      }

    case HASH_DEMO_FILE_SUCCESS:
      return {
        ...state,
        demoHash: action.payload.demoHash,
        hashingDemo: false,
      }

    case GET_UPLOAD_PROMPT:
      return {
        ...state,
        promptingUpload: true,
      }

    case GET_UPLOAD_PROMPT_SUCCESS:
      return {
        ...state,
        promptingUpload: false,
        uploadToken: action.payload.uploadToken,
      }

    case DEMO_FILE_PARSE_STARTED:
      return {
        ...state,
        demoFile: null,
        playerTeamA: null,
        playerTeamB: null,
        parsingDemo: true,
      }

    case DEMO_FILE_PARSE_SUCCESS:
      return {
        ...state,
        demoFile: action.payload.demoFile,
        playerTeamA: action.payload.playerTeamA,
        playerTeamB: action.payload.playerTeamB,
        parsingDemo: false,
        droppedFiles: action.payload.droppedFiles,
      }

    case DEMO_FILE_PARSE_FAILED:
      return {
        ...state,
        demoFile: null,
        playerTeamA: [],
        playerTeamB: [],
        parsingDemo: false,
      }

    case UPLOAD_DEMO_FILE_STARTED:
      return {
        ...state,
        uploading: true,
      }

    case UPLOAD_DEMO_FILE_SUCCESS:
      return {
        ...state,
        uploading: false,
        readyToSaveReplay: true,
      }

    default:
      return state
  }
}
