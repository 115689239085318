//Login / Session
export const LOGOUT = "LOGOUT"
export const FETCHING_AUTH = "FETCHING_AUTH"
export const SET_DISCORD_USER = "SET_DISCORD_USER"
export const AUTH_GET_USER_FAILED = "AUTH_GET_USER_FAILED"
export const AUTH_REQUEST_CODE_FAILED = "AUTH_REQUEST_CODE_FAILED"

//Replays
export const GET_REPLAY_LIST_SUCCESS = "GET_REPLAY_LIST_SUCCESS"
export const GET_ESPORT_GAME_LIST_SUCCESS = "GET_ESPORT_GAME_LIST_SUCCESS"
export const GET_ESPORT_GAME_LIST_FAILED = "GET_ESPORT_GAME_LIST_FAILED"
export const GET_ESPORT_GAME_REPLAY_LIST_SUCCESS = "GET_ESPORT_GAME_REPLAY_LIST_SUCCESS"
export const GET_ESPORT_GAME_REPLAY_LIST_FAILED = "GET_ESPORT_GAME_REPLAY_LIST_FAILED"
export const GET_REPLAY_DEFAULT_LIST_SUCCESS = "GET_REPLAY_DEFAULT_LIST_SUCCESS"
export const GET_REPLAYS_FAILED = "GET_REPLAY_LIST_FAILED"
export const SAVE_REPLAYS_SUCCESS = "SAVE_REPLAYS_SUCCESS"
export const SAVE_REPLAYS_FAILED = "SAVE_REPLAYS_FAILED"

//Demo Files
export const GET_UPLOAD_PROMPT = "GET_UPLOAD_PROMPT"
export const GET_UPLOAD_PROMPT_SUCCESS = "GET_UPLOAD_PROMPT_SUCCESS"
export const GET_UPLOAD_PROMPT_FAILED = "GET_UPLOAD_PROMPT_FAILED"
export const HASH_DEMO_FILE_STARTED = "HASH_DEMO_FILE_STARTED"
export const HASH_DEMO_FILE_PROGRESS = "HASH_DEMO_FILE_PROGRESS"
export const HASH_DEMO_FILE_SUCCESS = "HASH_DEMO_FILE_SUCCESS"
export const UPLOAD_DEMO_FILE_STARTED = "UPLOAD_DEMO_FILE_STARTED"
//export const UPLOAD_DEMO_FILE_PROGRESS = "UPLOAD_DEMO_FILE_PROGRESS";
export const UPLOAD_DEMO_FILE_SUCCESS = "UPLOAD_DEMO_FILE_SUCCESS"
export const DEMO_FILE_PARSE_STARTED = "DEMO_FILE_PARSE_STARTED"
export const DEMO_FILE_PARSE_SUCCESS = "DEMO_FILE_PARSE_SUCCESS"
export const DEMO_FILE_PARSE_FAILED = "DEMO_FILE_PARSE_FAILED"

//Search
export const SEARCH_SUCCESS = "SEARCH_SUCCESS"
export const SEARCH_FAILED = "SEARCH_FAILED"
export const CLEAR_RESULTS = "CLEAR_RESULTS"

//Esport Events
export const GET_ESPORT_EVENT_LIST_SUCCESS = "GET_ESPORT_EVENT_LIST_SUCCESS"
export const GET_ESPORT_EVENT_LIST_FAILED = "GET_ESPORT_EVENT_LIST_FAILED"

//Esport Teams
export const GET_ESPORT_TEAM_LIST_SUCCESS = "GET_ESPORT_TEAM_LIST_SUCCESS"
export const GET_ESPORT_TEAM_LIST_FAILED = "GET_ESPORT_TEAM_LIST_FAILED"

//Twitch
export const GET_DTV_TWITCH_CHANNEL_STATUS_SUCCESS = "GET_DTV_TWITCH_CHANNEL_STATUS_SUCCESS"
export const GET_DTV_TWITCH_CHANNEL_STATUS_FAILED = "GET_DTV_TWITCH_CHANNEL_STATUS_FAILED"

//Highlight Round
export const GET_HIGHLIGHT_ROUND_LIST_SUCCESS = "GET_HIGHLIGHT_ROUND_LIST_SUCCESS"
export const GET_HIGHLIGHT_ROUND_LIST_FAILED = "GET_HIGHLIGHT_ROUND_LIST_FAILED"

//replayMetadata
export const GET_METADATA_SUCCESS = "GET_METADATA_SUCCESS"
export const GET_METADATA_FAILED = "GET_METADATA_FAILED"
