import {
  LOGOUT,
  AUTH_GET_USER_FAILED,
  AUTH_REQUEST_CODE_FAILED,
  FETCHING_AUTH,
  SET_DISCORD_USER,
} from "../constants/actionTypes"

const initialState = {
  userSession: {
    error: null,
    fetching: false,
    isAuthenticated: false,
    activeDiscordUser: null,
  },
}

export default function userSessionReducer(state = initialState.userSession, action) {

  switch (action.type) {
    case AUTH_GET_USER_FAILED:
      return {
        ...state,
        activeDiscordUser: null,
        isAuthenticated: false,
        fetching: false,
        error: action.payload.error,
      }

    case AUTH_REQUEST_CODE_FAILED:
      return {
        ...state,
        activeDiscordUser: null,
        isAuthenticated: false,
        fetching: false,
        error: action.payload.error,
      }

    case LOGOUT:
      return {
        ...state,
        activeDiscordUser: null,
        isAuthenticated: false,
        fetching: false,
        error: null,
      }

    case FETCHING_AUTH:
      return {
        ...state,
        fetching: true,
      }

    case SET_DISCORD_USER:
      return {
        ...state,
        activeDiscordUser: action.payload.user,
        isAuthenticated: true,
        fetching: false,
      }

    default:
      return state
  }
}
