import "bootstrap/dist/css/bootstrap.min.css"
export { default as wrapRootElement } from "./src/state/ReduxWrapper"

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://platform.twitter.com/widgets.js")
    addScript("https://apis.google.com/js/platform.js")
    addScript("https://www.youtube.com/iframe_api")
  }
}
