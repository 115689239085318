import {
  GET_ESPORT_EVENT_LIST_SUCCESS,
} from "../constants/actionTypes"

const initialState = {
  esportEvents: {
    fetchingEsportEvents: null,
    list: [],
  },
}

export default function esportEventsReducer(state = initialState.esportEvents, action) {

  switch (action.type) {
    case GET_ESPORT_EVENT_LIST_SUCCESS:
      return {
        ...state,
        fetchingEsportEvents: false,
        list: action.payload.list,
      }
    default:
      return state
  }
}
