import { combineReducers } from "redux"
import userSession from "./userSessionReducer"
import replays from "./replaysReducer"
import uploads from "./uploadsReducer"
import search from "./searchReducer"
import esportEvents from "./esportEventsReducer"
import esportTeams from "./esportTeamsReducer"
import twitch from "./twitchReducer"
import highlightRound from "./highlightRoundReducer"
import replayMetaData from "./replayMetaDataReducer"

const rootReducer = combineReducers({
  userSession,
  replays,
  uploads,
  search,
  esportEvents,
  esportTeams,
  twitch,
  highlightRound,
  replayMetaData,
})

export default rootReducer
