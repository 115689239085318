import {
  GET_REPLAY_LIST_SUCCESS,
  GET_REPLAY_DEFAULT_LIST_SUCCESS,
  GET_ESPORT_GAME_LIST_SUCCESS,
  GET_ESPORT_GAME_REPLAY_LIST_SUCCESS,
} from "../constants/actionTypes"

const initialState = {
  replays: {
    list: [],
    trendingList: [],
    latestList: [],
    esportGameList: [],
    esportGameReplayList: [],
  },
}

export default function replaysReducer(state = initialState.replays, action) {

  switch (action.type) {
    case GET_REPLAY_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
      }

    case GET_REPLAY_DEFAULT_LIST_SUCCESS:
      return {
        ...state,
        trendingList: action.payload.trendingList,
        latestList: action.payload.latestList,
      }

    case GET_ESPORT_GAME_LIST_SUCCESS:
      return {
        ...state,
        esportGameList: action.payload.esportGameList,
      }

    case GET_ESPORT_GAME_REPLAY_LIST_SUCCESS:
      return {
        ...state,
        esportGameReplayList: action.payload.esportGameReplayList,
      }

    default:
      return state
  }
}
