import {
  GET_ESPORT_TEAM_LIST_SUCCESS,
} from "../constants/actionTypes"

const initialState = {
  esportTeams: {
    fetchingEsportTeams: null,
    list: [],
  },
}

export default function esportTeamsReducer(state = initialState.esportTeams, action) {

  switch (action.type) {
    case GET_ESPORT_TEAM_LIST_SUCCESS:
      return {
        ...state,
        fetchingEsportTeams: false,
        list: action.payload.list,
      }
    default:
      return state
  }
}
