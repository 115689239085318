import {
  GET_HIGHLIGHT_ROUND_LIST_SUCCESS,
} from "../constants/actionTypes"

const initialState = {
  highlightRound: {
    fetchinghighlightRound: null,
    list: [],
  },
}

export default function highlightRoundReducer(state = initialState.highlightRound, action) {

  switch (action.type) {
    case GET_HIGHLIGHT_ROUND_LIST_SUCCESS:
      return {
        ...state,
        fetchinghighlightRound: false,
        list: action.payload.list,
      }
    default:
      return state
  }
}
