import {
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  CLEAR_RESULTS,
} from "../constants/actionTypes"

const initialState = {
  search: {
    results: null,
  },
}

export default function searchReducer(state = initialState.search, action) {

  switch (action.type) {
    case SEARCH_SUCCESS:
      return {
        ...state,
        results: action.payload.results,
      }

    case SEARCH_FAILED:
      return {
        ...state,
      }

    case CLEAR_RESULTS:
      return {
        ...state,
        results: null,
      }

    default:
      return state
  }
}
