import {
  GET_METADATA_SUCCESS,
  GET_METADATA_FAILED,
} from "../constants/actionTypes"

const initialState = {
  metaDatas: [],
}

export default function replayMetaDataReducer(state = initialState.metaDatas, action) {
  switch (action.type) {
    case GET_METADATA_SUCCESS:
      return {
        ...state,
        metaDatas: action.payload.StuffSent,
      }
    case GET_METADATA_FAILED:
      return {
        ...state,
      }
    default:
      return state
  }
}
